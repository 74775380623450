import React from "react";
import * as Loader from "react-loader-spinner";
import { logo } from "./CommonVarFunc";

 export default function ModalLoaderUi(){

    return(<>
  
   <div className="d-flex justify-content-center"  >
        <div className="d-flex p-2 col-example" >
        <img  src= {logo}width="100px" style={{margin:"20px"}}/>
        
          </div></div>
          <div className="d-flex justify-content-center"  >
        <div className="d-flex p-2 col-example" >
          

        <h1> Processing Your Request</h1><br/>
   
          </div></div>


          <div className="d-flex justify-content-center"  >
        <div className="d-flex p-2 col-example" >
          
        <Loader.ThreeDots

    color="#38D4DB"
    height={70}
    width={200}
    
    />
          </div></div>

         
    </>
    )
}