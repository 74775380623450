export const logo = "https://firebasestorage.googleapis.com/v0/b/mymmuz-nearby-f58e2.appspot.com/o/dhanbadclub%2Flogo.png?alt=media&token=9287a823-9a1b-4290-abf6-2b8730ba6fb3"

export function  removeSpaces(str){
    return str.replaceAll(/\s/g,'')
}

export function toTitleCase(str) {
    if(str!="" && typeof(str)=="string"){
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );} else return str
  }