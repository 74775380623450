import React, { useEffect, useState } from "react";
import ModalLoaderUi from "../Common/ModalLoader";
import firebase from "../firebase"
import "./Modal.css";

function FileSelectModal({ setOpenModal, setFileurl, setFileType}) {
  
  const [loading, setLoading] = useState(false)
  function OnChangeHandler(e){
    setLoading(true)
   const file = e.target.files[0]
    let filename=""
   
    
  if(file['type'].includes("image")){
    filename = "image.png"
  }
  if(file['type'].includes("application/pdf")){
    filename = "abc.pdf"
  }
  if(file['type'].includes("application/msword")){
    filename = "abc.doc"
  }
  setFileType(file['type'])
   firebase.app().storage("gs://mymmuz-nearby-f58e2.appspot.com").ref().child(filename).put(file).then((doc)=>{
    doc.ref.getDownloadURL().then((url)=>{
      setFileurl(url)
      setOpenModal(false)
    })
   })
  }
  return (
    <div className="modalBackground">
      <div className="modalContainer" style={{maxWidth:"auto !important"}}>
        <div className="titleCloseBtn">
          <button
            onClick={() => 
                setOpenModal(false)
            }
          >
            X
          </button>
        </div>
        <div className="title" >
          <h2>Select File</h2>

        </div>
        <div className="body">
          {loading ? <ModalLoaderUi/>:
            <input type="file" accept="image/*,.pdf,.doc" onChange={(e)=> OnChangeHandler(e)} />
          }
        </div>
        <div className="footer">
          {/* <button
            onClick={() => {
              setOpenModal(false);
            }}
         
          >
          Okay
          </button> */}
      
        </div>
      </div>
    </div>
  );
}

export default FileSelectModal;