import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { BrowserRouter } from 'react-router-dom';
import './App.css';

import CustomMessage from './Messaging/CustomMessage';
import { AuthProvider } from './auth/Auth';
import PrivateRoute from "./PrivateRouter"
import Login from './auth/Login';


function App() {

  return (
    <BrowserRouter>
    <AuthProvider>
     
        <div>
          <PrivateRoute exact path="/"    component={CustomMessage}   />  
          <Route exact path="/login" component={Login} />
          
        </div>
        </AuthProvider>
      </BrowserRouter>
    
  
     
      
  
  );
}

export default App;